import Vue from 'vue'
export const showAlert = () => {
  Vue.$toast.info('Kode pos maksimal 2 karakter')
}
export const multipleShow = ({ data = [], value, obj1, obj2 } = {}) => {
  return data.map(data => {
    return {
      value: data[value],
      show: `${data[obj1]} | ${data[obj2]}`
    }
  })
}

export const defaultReturn = (val, isDefault) => {
  return isNaN(parseInt(val)) ? isDefault : parseInt(val)
}

export const returnTrue = (val, isTrue) => {
  return val || isTrue.includes(val)
}

/**
 * Validasi nomor telepon.
 */
export const validatePhoneNumber = event => {
  const start = event.target.selectionStart
  const { target } = event
  if (target.value[0] !== 0 && target.value !== '') target.value = '0'
  if (target.value !== target.value.replace(/[^0-9|-]/g, '')) {
    target.value = target.value.replace(/[^0-9|-]/g, '')
    Vue.$toast.info('Mohon masukkan nomor yang valid')
    target.setSelectionRange(start, start - 1)
  } else {
    target.setSelectionRange(start, start)
  }
}

export const dateInaToSentance = ({
  format = 'dd/mm/yy',
  date = null
} = {}) => {
  const DAYS = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
  const MONTHS = [
    'Januari',
    'Febuari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'
  ]
  if (new Date(date) && format === 'dd/mm/yy') {
    date = date.split('/')
    date = new Date(date[1] + '/' + date[0] + '/' + date[2])
    return `${DAYS[parseInt(new Date(date).getDay())]}, ${new Date(
      date
    ).getDate()} ${MONTHS[new Date(date).getMonth()]} ${new Date(
      date
    ).getFullYear()}`
  }

  return 'Format tidak valid'
}
export const newDateFromEnFormat = date => {
  var dateString = date

  var dateParts = dateString?.split('/')

  // month is 0-based, that's why we need dataParts[1] - 1
  return dateParts
    ? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    : false
}

export const dateLocaleIdtoLocaleEn = dateString => {
  const date = dateString.split('/')
  if (date.length === 3) {
    return `${date[1]}/${date[0]}/${date[2]}`
  }
  return false
}
export const getInitialsName = fullname => {
  const name = fullname?.split(' ')
  let initial = fullname?.charAt(0)
  if (name?.length > 1) {
    initial = name[0].charAt(0) + name[name.length - 1].charAt(0)
  }

  return initial
}

// convert to rupiah
// use regex
export const toRupiah = number => {
  const convert = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const rupiah = convert ? 'Rp. ' + convert : false
  return rupiah
}

export const isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i)
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  }
}

export const date2str = (x, y) => {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds()
  }
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
    return ((v.length > 1 ? '0' : '') + z[v.slice(-1)]).slice(-2)
  })

  return y.replace(/(y+)/g, function(v) {
    return x
      .getFullYear()
      .toString()
      .slice(-v.length)
  })
}

export class Utils {
  static convertMasa(masa) {
    const suffix = masa?.toString()?.slice(-1) === '1' ? 'Ganjil' : 'Genap'
    return `${masa.slice(0, 4)} ${suffix}`
  }
}
